import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import OrderedList from "~/components/List/Ordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={10}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 10</H2>

          <p>Your teacher will wrap up this lesson with a class discussion. Take a few minutes to think about how you would answer questions that will be part of that discussion. Share ideas with your teammates.</p>

          <OrderedList>
            <li>Suppose you have found information from 2 different sources. One is a website and one is a magazine. Would your rubric be helpful for thinking about the quality of information in both sources? What criteria on your rubric would apply to both sources? Are there criteria that do not apply to both sources?</li>
            <li>At one time, polio was a disease that crippled many people. Suppose you want to find out how Jonas Salk developed a vaccine to protect people from getting polio. Are there any website features that would be either more important or less important when deciding which sites might be better for answering your question? Why?</li>
            <li>How might it be helpful to do a quick review of a website’s features when searching the internet for information?</li>
          </OrderedList>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
